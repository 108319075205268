import React from "react";
import {
  Avatar,
  Grid,
  Box,
  Drawer,
  makeStyles,
  useTheme,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import { BiLogOut } from "react-icons/bi";
import Menu from "./menu";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutRequest } from "src/redux/auth/action";
import { loginSelector } from "src/redux/auth/selector";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 280,
    flexShrink: 0,
    color: "white",
  },
  paper: {
    width: 280,
    backgroundColor: "#484848",
    borderRight: 0,
    display: "flex",
    overflowY: "overlay",
    boxShadow:
      "2px 64px 4px 0px rgb(0 0 0 / 7%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 10%)",
  },
  logoContainer: {
    color: "white",
    width: "100%",
    marginTop: theme.spacing(1.8),
    alignItems: "center",
    justifyItems: "center",
    position: "absolute",
  },
  logo: {
    margin: "0em auto 0.7em auto",
    width: "45%",
    "& img": {
      objectFit: "contain",
    },
  },
  profileName: {
    fontWeight: "bold",
    fontSize: 20,
    marginLeft: "24px",
  },
  messageArea: {
    fontWeight: "bold",
    fontSize: 15,
    marginLeft: "24px",
  },
  message: {
    color: "white",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: "#dddddd",
    },
  },
  notification: {
    fontWeight: "bold",
    fontSize: 15,
    backgroundColor: "red",
    padding: "0.2em 0.5em",
    borderRadius: 20,
  },
  logoutArea: {
    width: "100%",
    backgroundColor: "#D32121",
    padding: "15px 0",
    textAlign: "center",
    borderRadius: "0",
    color: "white",
    fontFamily: "Roboto",
  },
}));

const DrawerComponent = ({ open, handleClose }) => {
  const classes = useStyles();
  const { loginResponse } = useSelector(loginSelector);
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutRequest());
  };

  return (
    <Drawer
      open={open}
      variant={!matchSM ? "permanent" : "temporary"}
      onClose={handleClose}
      className={classes.drawer}
      classes={{
        paper: classes.paper,
      }}
    >
      <Box className={classes.logoContainer}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Avatar
              src="/logo.png"
              alt="logo"
              className={classes.logo}
              variant="square"
            />
          </Grid>
          <Grid item className={classes.profileName}>
            {loginResponse?.user?.firstname.toUpperCase()}{" "}
            {loginResponse?.user?.lastname}
          </Grid>
          <Grid item className={classes.messageArea}>
            <Link to="/message" className={classes.message}>
              Message
            </Link>{" "}
            <span className={classes.notification}>2</span>
          </Grid>
        </Grid>
      </Box>
      <Menu handleClose={handleClose} />
      <Box>
        <Button className={classes.logoutArea} onClick={handleLogout}>
          <BiLogOut />
          &nbsp;&nbsp;LOGOUT
        </Button>
      </Box>
    </Drawer>
  );
};

export default DrawerComponent;
