import { Grid, makeStyles, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
// import CustomButton from "./button/custom-button";
import MiniProfile from "./mini-profile";
import PaperContainer from "./paper-container";
import clsx from "clsx";

dayjs.extend(relativeTime);
const useStyles = makeStyles((themes) => ({
  messageResponse: {
    padding: 10,
    justifyContent: "center",
    borderRadius: 5,
  },
  awaitingResponse: {
    backgroundColor: themes.palette.primary.warning,
    color: "black",
  },
  accepted: {
    backgroundColor: themes.palette.primary.main,
    color: "white",
  },
  rejected: {
    backgroundColor: themes.palette.primary.danger,
    color: "white",
  },
}));

const MessageCard = ({
  _id = "testing",
  username = "Michael Devel",
  organization = "Appiwat IT",
  profileUrl = "https://scontent.fpnh1-1.fna.fbcdn.net/v/t39.30808-6/291804369_951250089105162_3491672423398316828_n.jpg?_nc_cat=1&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=Hs89_yNLqBcAX88v_V1&tn=N-qKXMDCFOF4xDLy&_nc_ht=scontent.fpnh1-1.fna&oh=00_AT-23sCAQ5QCQ3KxP1WHDSFgeqa4L-j5HzukyTEy6tNgFQ&oe=62C82DD7",
  subject = "Subject of the Message",
  content = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  createdAt = Date.now(),
  isAccepted,
  isResponded,
}) => {
  const classes = useStyles();

  const checkResponseStatus = () => {
    let status = null;

    if (!isResponded) {
      status = "Awaiting Response";
    } else if (isResponded && isAccepted) {
      status = "ACCEPTED";
    } else if (isResponded && !isAccepted) {
      status = "REJECTED";
    }

    return (
      <Grid item>
        <Typography variant="h3">{status}</Typography>
      </Grid>
    );
  };

  return (
    <PaperContainer style={{ margin: "1em 0" }}>
      <Grid container spacing={10} justifyContent="space-between">
        <Grid item xs={12} md={10}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <MiniProfile
                _id={_id}
                username={username}
                organization={organization}
                profileUrl={profileUrl}
              />
            </Grid>
            <Grid item>
              <Typography variant="h5">{subject}</Typography>
            </Grid>
            <Grid item>
              <Typography style={{ whiteSpace: "pre-wrap" }}>
                {content}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          style={{ backgroundColor: "#eeeeee", padding: 20 }}
        >
          <Grid
            container
            spacing={1}
            alignContent="space-between"
            style={{ height: "100%", textAlign: "center" }}
          >
            <Grid item xs={12}>
              {dayjs().to(createdAt)}
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                className={clsx({
                  [classes.messageResponse]: true,
                  [classes.awaitingResponse]: !isResponded,
                  [classes.accepted]: isResponded && isAccepted,
                  [classes.rejected]: isResponded && !isAccepted,
                })}
              >
                {checkResponseStatus()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

export default MessageCard;
