import {
  Box,
  Grid,
  Paper,
  makeStyles,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Delete, Send, NavigateNext } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import millify from "millify";
import ProfilePicture from "./profile-picture";
import CustomButton from "./Button/custom-button";

const useStyles = makeStyles((theme) => ({
  title: {
    // color: theme.palette.primary.main,
    fontSize: "1.5em",
    fontWeight: "bold",
  },
  paperContainer: {
    boxShadow: "0 0 4px #bbbbbb !important",
    padding: "2em 3em",
    marginBottom: 15,
    "&:hover": {
      boxShadow: "0 0 15px #bbbbbb !important",
    },
  },
  contactInfo: {
    listStyle: "none",
    "& li": {
      padding: 5,
    },
  },
  customChip: {
    display: "inline-flex",
    backgroundColor: theme.palette.primary.chip,
    padding: "0.5em 0.75em",
    borderRadius: 50,
    fontSize: "13px",
    marginRight: "0.75em",
    marginBottom: 5,
  },
  redDot: {
    height: "15px",
    width: "15px",
    marginLeft: "0.5em",
    backgroundColor: "red",
    borderRadius: "50%",
    display: "inline-block",
  },
  greenDot: {
    height: "15px",
    width: "15px",
    marginLeft: "0.5em",
    backgroundColor: "#0fa331",
    borderRadius: "50%",
    display: "inline-block",
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonMain: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const CandidatePaper = ({
  _id,
  firstname,
  lastname,
  salary,
  skills,
  therapeutics,
  executiveSummary,
  relocation,
  sponsorship,
  profileUrl,
  currentLocation,
  handleDelete,
  inputKey,
  isConnected
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Paper className={classes.paperContainer} key={inputKey}>
      <Grid container spacing={1}>
        {/* <Grid item xs={12} container justifyContent="flex-end">
          <IconButton
            color="primary"
            onClick={() => handleDelete(_id, inputKey)}
          >
            <Delete />
          </IconButton>
        </Grid> */}
        <Grid item spacing={4}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={3}
              justifyContent="center"
              spacing={1}
              alignItems="center"
              container
              direction="column"
            >
              <Grid item>
                <ProfilePicture src={profileUrl} />
              </Grid>
              <Grid item>
                <b>{(isConnected) ? `${firstname} ${lastname}` : 'N/A'}</b>
              </Grid>
              {/* <Grid item>Software Engineer</Grid> */}
            </Grid>
            <Grid item xs={12} md={9} container>
              <Grid xs={12}>
                <Box className={classes.customChip}>
                  <b>Salary Range&nbsp;</b>
                  {`${millify(salary?.from || 0)} - ${millify(
                    salary?.to || 0
                  )} USD`}
                </Box>
                <Box className={classes.customChip}>
                  <b>Location&nbsp;</b>
                  {currentLocation}
                </Box>
                <Box className={classes.customChip}>
                  <b>Relocation</b>
                  <span
                    className={relocation ? classes.greenDot : classes.redDot}
                  >
                    &nbsp;
                  </span>
                </Box>
                <Box className={classes.customChip}>
                  <b>Sponsorship</b>{" "}
                  <span
                    className={sponsorship ? classes.greenDot : classes.redDot}
                  >
                    &nbsp;
                  </span>
                </Box>
              </Grid>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Grid>
                    <Typography variant="h5">Skills</Typography>
                  </Grid>
                  <Grid>
                    <Typography>
                      {skills.map((skill) => skill.value.title).join(", ")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid>
                    <Typography variant="h5">Therapeutic Skills</Typography>
                  </Grid>
                  <Grid>
                    <Typography>
                      {therapeutics
                        .map((therapeutic) => therapeutic.value.title)
                        .join(", ")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{
            marginTop: 15,
            paddingTop: 15,
            borderTop: `1px solid #cccccc`,
          }}
        >
          <Grid item xs={12} md={10}>
            <Typography variant="h3">Executive Summary</Typography>
            <Typography style={{ paddingTop: 10, whiteSpace: "pre-line" }}>
              {executiveSummary}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            alignItems="flex-end"
            alignContent="flex-end"
          >
            <CustomButton
              endIcon={<NavigateNext />}
              onClick={() => history.push(`/candidate/${_id}`)}
              caption="View Profile"
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CandidatePaper;
