import { useEffect, useState } from "react";
import {
  Chip,
  Box,
  Grid,
  makeStyles,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  Star,
  Message,
  CheckCircleRounded,
  CancelRounded,
} from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import axios from "axios";
import PaperContainer from "src/components/paper-container";
import ProfilePicture from "src/components/profile-picture";
import ContentSection from "src/components/content-section";
import Chart from "react-google-charts";
import { Rating } from "@material-ui/lab";
import { languageRatingLabels } from "../create-candidate/index";
import ControlledTextField from "src/components/controlled-components/controlled-text-field";
import CustomButton from "src/components/Button/custom-button";
import MessageApi from "src/api/message";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    marginBottom: 60,
    display: "flex",
  },
  label: {
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  listStyle: {
    listStyle: "none",
    padding: 0,
  },
  profileExtraInfo: {
    backgroundColor: "#dddddd",
    padding: "0.75em 1em",
    borderRadius: "0px 0px 2.5px 2.5px",
  },
  button: {
    padding: "0.25em 1.25em !important",
    backgroundColor: theme.palette.primary.main,
  },
  customChip: {
    backgroundColor: theme.palette.primary.main,
    margin: 3,
    color: "white",
    "& .MuiChip-deleteIcon": {
      color: "white",
    },
  },
}));

const Profile = () => {
  const classes = useStyles();
  const languageGraphColor = ["#e5e4e2", "blue", "gold", "red"];
  const [candidate, setCandidate] = useState([]);
  const [messageFormOpen, setMessageFormOpen] = useState(false);
  const [skillGraphData, setSkillGraphData] = useState([
    [
      { type: "string", id: "Position" },
      { type: "date", id: "Start" },
      { type: "date", id: "End" },
    ],
  ]);
  const [therapeuticGraphData, setTherapeuticGraphData] = useState([
    ["therapeutics", "duration"],
  ]);
  const [languageGraphData, setLanguageGraphData] = useState([
    [
      "Language",
      "Proficiency",
      { role: "style" },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
    ],
  ]);
  const { candidateId } = useParams();
  const { register, control, handleSubmit, reset } = useForm({});

  useEffect(() => {
    const fetchCandidate = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/candidate/${candidateId}`
        );

        let skillGraphData = data.candidate.skills.map((skill) => [
          skill.value.title,
          new Date(skill.startDate),
          new Date(skill.endDate),
        ]);

        let therapeuticGraphData = data.candidate.therapeutics.map(
          (therapeutic) => [
            therapeutic.value.title,
            Number(therapeutic.percentage),
          ]
        );

        let languageGraphData = data.candidate.languages.map((lang) => [
          lang.name,
          lang.proficiency?.level,
          languageGraphColor.pop(),
          null,
        ]);

        setCandidate(data.candidate);
        setSkillGraphData((prev) => [...prev, ...skillGraphData]);
        setTherapeuticGraphData((prev) => [...prev, ...therapeuticGraphData]);
        setLanguageGraphData((prev) => [...prev, ...languageGraphData]);
      } catch (err) {
        console.log("failed-to-get-candidate-by-id", err);
      }
    };

    fetchCandidate();
  }, []);

  const submitMessage = async (data) => {
    try {
      console.log(data);

      const res = await MessageApi.create(data);
      // console.log(res);

      setMessageFormOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const DescriptionWithLabel = ({ label, value }) => {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <Typography className={classes.label}>{label}</Typography>
        </Grid>
        <Grid item>
          <Typography>{value}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <PaperContainer>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={3}>
                <ProfilePicture
                  src={candidate.profileUrl || `/profile-placeholder.png`}
                  name={candidate.firstname || candidate.lastname ? `${candidate.firstname} ${candidate.lastname}`.trim() : "N/A"}
                // position="Software Engineer"
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <DescriptionWithLabel
                          label="salary range"
                          value={candidate.salary?.from || candidate.salary?.to ? `${candidate.salary?.from?.toLocaleString("en-US")} 
                          USD - ${candidate.salary?.to?.toLocaleString("en-US")} USD` : "N/A"}
                        />
                        <DescriptionWithLabel
                          label="contract type"
                          value={
                            candidate.contractType
                              ?.map((contract) => contract.title)
                              .join(", ") || "N/A"
                          }
                        />
                        <DescriptionWithLabel
                          label="work preferences"
                          value={candidate.workPreference || "N/A"}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <DescriptionWithLabel
                          label="current location"
                          value={candidate.currentLocation || "N/A"}
                        />
                        <DescriptionWithLabel
                          label="availability"
                          value={candidate.availability || "N/A"}
                        />
                        <DescriptionWithLabel
                          label="hour availability"
                          value={
                            candidate.hourAvailability
                              ?.map((hourAvailable) => hourAvailable.title)
                              .join(", ") || "N/A"
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" spacing={1}>
                    <Grid item>
                      <Typography className={classes.label}>
                        Company Type
                      </Typography>
                    </Grid>
                    <Grid item>
                      {candidate.companyType?.map((item, key) => (
                        <Box component="span" key={key}>
                          <Chip label={item.title} />
                          &nbsp;
                        </Box>
                      ))}
                    </Grid>
                  </Grid>
                  {/* <Grid container item direction="column" spacing={1}>
                  <Grid item>
                    <Typography className={classes.label}>
                      Recommended Jobs
                    </Typography>
                  </Grid>
                  <Grid item>
                    {candidate.jobs?.map((job, key) => (
                      <>
                        <Chip label={job.title} key={key} />
                        &nbsp;
                      </>
                    ))}
                  </Grid>
                </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </PaperContainer>
          <Grid container className={classes.profileExtraInfo}>
            <Grid container item xs={12} md={7}>
              <Chip
                className={classes.customChip}
                deleteIcon={
                  candidate.relocation ? (
                    <CheckCircleRounded />
                  ) : (
                    <CancelRounded />
                  )
                }
                onDelete={() => console.log("nth")}
                label="Relocation"
              />
              <Chip
                className={classes.customChip}
                deleteIcon={
                  candidate.sponsorship ? (
                    <CheckCircleRounded />
                  ) : (
                    <CancelRounded />
                  )
                }
                onDelete={() => console.log("nth")}
                label="Sponsorship"
              />
              <Chip
                className={classes.customChip}
                deleteIcon={
                  candidate.previousLineManagement ? (
                    <CheckCircleRounded />
                  ) : (
                    <CancelRounded />
                  )
                }
                onDelete={() => console.log("nth")}
                label="Previous Line Management"
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={5}
              justifyContent="flex-end"
              spacing={1}
            >
              <Grid item>
                <CustomButton
                  caption={candidate.isConnected ? 'SEND A MESSAGE' : 'ADD CONNECTION'}
                  startIcon={<Message />}
                  onClick={() => {
                    reset({});
                    setMessageFormOpen(true);
                  }}
                />
              </Grid>
              <Grid item>
                <CustomButton
                  startIcon={<Star />}
                  caption="Favorite"
                  customVariant="danger"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PaperContainer>
            <ContentSection title="Executive Summary">
              <span style={{ whiteSpace: "pre-line" }}>
                {candidate.executiveSummary}
              </span>
            </ContentSection>
            <ContentSection title="Motivations">
              <span style={{ whiteSpace: "pre-line" }}>
                {candidate.motivations}
              </span>
            </ContentSection>
            <ContentSection title="Qualifications">
              <ul className={classes.listStyle}>
                {candidate.qualifications?.map((item, key) => (
                  <li key={key}>{item.title}</li>
                ))}
              </ul>
            </ContentSection>
            <ContentSection title="Skills">
              <ul className={classes.listStyle}>
                {candidate.skills?.map((skill, key) => (
                  <li key={key}>
                    <Grid container spacing={2}>
                      <Grid item xs={1} style={{ fontWeight: "bold" }}>
                        {new Date(skill.startDate).getFullYear()} -{" "}
                        {new Date(skill.endDate).getFullYear()}
                      </Grid>
                      <Grid item xs={2}>
                        {skill.value.title}
                      </Grid>
                      <Grid item>
                        <Rating
                          name={`candidate.skills.${key}.proficiency`}
                          value={Number(skill.proficiency)}
                          precision={0.5}
                          readOnly
                        />
                      </Grid>
                      <Grid item>
                        {skill.proficiency !== null &&
                          languageRatingLabels[skill.proficiency]}
                      </Grid>
                    </Grid>
                  </li>
                ))}
              </ul>
            </ContentSection>
            <ContentSection title="Therapeutic Skills">
              <ul className={classes.listStyle}>
                {candidate.therapeutics?.map((therapeutic, key) => (
                  <li key={key}>
                    <Grid container spacing={2}>
                      <Grid item xs={1} style={{ fontWeight: "bold" }}>
                        {new Date(therapeutic.startDate).getFullYear()} -{" "}
                        {new Date(therapeutic.endDate).getFullYear()}
                      </Grid>
                      <Grid item xs={2}>
                        {therapeutic.value.title}
                      </Grid>
                    </Grid>
                  </li>
                ))}
              </ul>
            </ContentSection>
            <ContentSection title="Language Proficiency">
              <ul className={classes.listStyle}>
                {candidate.languages?.map((language, key) => (
                  <li key={key}>
                    <Grid container spacing={2}>
                      <Grid item xs={1}>
                        <b>{language.name} </b>
                      </Grid>
                      <Grid item>
                        <Rating
                          name={`candidate.languages.${key}.proficiency`}
                          value={Number(language.proficiency)}
                          precision={0.5}
                          readOnly
                        />
                      </Grid>
                      <Grid item>
                        {language.proficiency !== null &&
                          languageRatingLabels[language.proficiency]}
                      </Grid>
                    </Grid>
                  </li>
                ))}
              </ul>
            </ContentSection>
          </PaperContainer>
        </Grid>
        <Grid item xs={12}>
          <PaperContainer>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <h5>Technical Skills</h5>
                <Chart
                  chartType="Timeline"
                  data={skillGraphData || "N/A"}
                  width="100%"
                  options={{
                    title: "Technical Skills",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Chart
                  chartType="PieChart"
                  data={therapeuticGraphData}
                  options={{
                    title: "Therapeutic Skills",
                    height: 300,
                  }}
                />
              </Grid>
            </Grid>
          </PaperContainer>
        </Grid>
      </Grid>
      <Dialog
        open={messageFormOpen}
        onClose={() => setMessageFormOpen(false)}
        aria-labelledby="form-dialog-message"
      >
        <form onSubmit={handleSubmit(submitMessage)}>
          <DialogTitle id="form-dialog-message" disableTypography>
            <Typography variant="h4">
              Message to {candidate.firstname} {candidate.lastname}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              If the candidate accepted your request, you will be able to see
              the full candidate information.
            </DialogContentText>
            <Grid container spacing={2}>
              <input
                type="hidden"
                {...register("candidateId")}
                value={candidateId}
              />
              <ControlledTextField
                name="subject"
                control={control}
                placeholder="Subject"
                fullWidth
                xs={12}
                rules={{ required: true }}
              />
              <ControlledTextField
                name="message"
                control={control}
                placeholder="Leave a message..."
                multiline
                fullWidth
                xs={12}
                rules={{ required: true }}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setMessageFormOpen(false)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default Profile;

// export const options = {
//   title: "Language Proficiency",
//   legend: { position: "none" },
// };
