const APP_EN = {
  // global
  "created-by": "Created By",
  action: "Action",
  search: "Search",
  description: "Description",
  cancel: "Cancel",
  add: "Add",
  unit: "Unit",
  brand: "Brand",
  note: "Note",
  icon: "Icon",
  sku: "SKU",
  upc: "UPC/EAN/ISBN/PART NUMBER",
  name: "Name",
  email: "E-mail",
  phone: "Phone Number",
  website: "Website",
  address: "Address",
  fullname: "FullName",
  position: "Position",
  "low-stock": "LOW STOCK LIMIT (REORDER ALERT)",
  city: "City",
  firstname: "First Name",
  lastname: "Last Name",
  gender: "Gender",
  "date-of-birth": "Date of Birth",
  vendor: "Vendor",
  "created-date": "Created Date",
  image: "Image",
  "product-name": "Product Name",
  quantity: "Quantity",
  "cost-price": "Cost Price",
  tax: "Tax",
  amount: "Amount",
  status: "Status",
  subtotal: "Subtotal",
  "shipping-fee": "Shipping Fee",
  "other-fee": "Other Fee",
  discount: "Discount",
  "grand-total": "Grand Total",
  update: "Update",
  "unit-price": "Unit Price",
  save: "Save",
  barcode: "Barcode",
  print: "Print",
  "edit-options": "Edit Options",
  filter: "Filter",
  export: "Export",
  "before-tax": "Before Tax",
  "payment-status": "Payment Status",
  "save-changes": "Save Changes",
  cash: "Cash",
  "stock-on-hand": "Stock on hand",
  "are-you-sure": "Are you sure?",
  confirm: "Confirm",
  "action-cant-undone": "Your action cannot be undone.",
  "action-confirmation": "Action Confirmation",
  "returned-confirmation": "Returned Confirmation",
  "no.": "No.",
  total: "Total",

  // status
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
  packing: "Packing",
  shipping: "Shipping",
  delivered: "Delivered",
  "delivery-failed": "Delivery Failed",
  failed: "Failed",
  cancelled: "Cancelled",
  complete: "Complete",
  returned: "Returned",
  "partially-returned": "Partially Returned",
  unpaid: "Unpaid",
  paid: "Paid",
  "partially-paid": "Partially Paid",

  // title bar
  "titlebar-dashboard": "Dashboard",
  "titlebar-unit": "List Unit",
  "titlebar-brand": "List Brand",
  "titlebar-category": "List Category",
  "titlebar-product": "List Product",
  "titlebar-product-create": "Create Product",
  "titlebar-product-edit": "Edit Product",
  "titlebar-currency": "List Currency",
  "titlebar-vendor": "List Vendor",
  "titlebar-vendor-create": "Create Vendor",
  "titlebar-vendor-edit": "Edit Vendor",
  "titlebar-customer": "List Customer",
  "titlebar-customer-create": "Create Customer",
  "titlebar-customer-edit": "Edit Customer",
  "titlebar-purchase": "List Purchase",
  "titlebar-purchase-create": "Create Purchase",
  "titlebar-purchase-edit": "Edit Purchase",
  "titlebar-sale-order": "List Sale Order",
  "titlebar-sale-order-create": "Create Order",
  "titlebar-sale-order-edit": "Edit Order",
  "titlebar-stock": "List Stock",
  "titlebar-location": "List Location",

  // product page
  "create-product": "Create Product",
  "edit-product": "Edit Product",
  "summary-description": "Summary Description",
  "age-variation": "Age Variation",
  "create-attributes-and-options": "CREATE ATTRIBUTES AND OPTIONS",
  "purchase-information": "Purchase Information",
  "selling-price": "Selling Price",
  "product-description": "Product Description",
  "low-stock-limit": "Low Stock Limit (Reorder Alert)",
  "selling-information": "Selling Information",
  attribute: "Attribute",
  options: "Options",
  "add-more-attribute": "Add More Attributes",
  "item-name": "Item Name",
  "product-information": "Product Information",
  "generate-sku": "Generate SKU",
  "copy-to-all": "Copy to All",
  "sku-data-insufficient": "Please fill in (product name, vendor, brand)",
  "fill-product-name": "Please fill in product name",
  "fill-in-first-field": "Please fill in the first field",

  // unit page
  "unit-name": "Unit Name",
  "create-unit": "Create Unit",
  "unit-form-title": "UNIT INFORMAION",
  "unit-placeholder": "EG: PCS",

  // brand page
  "brand-form-title": "BRAND INFORMATION",
  "create-brand": "Create Brand",
  "brand-name": "Brand Name",

  // category page
  "create-category": "Create Category",
  "category-form-title": "CATEGORY INFORMATION",
  "category-name": "CATEGORY NAME",
  "parent-category": "PARENT CATEGORY",

  // currency page
  "currency-name": "Currency Name",
  "currency-symbol": "Currency Symbol",
  "currency-form-title": "CURRENCY INFORMATION",
  "price-per-dollar": "Price Per 1USD",
  "create-currency": "New Currency",

  // vendor page
  "company-information": "Company Information",
  "vendor-form-title": "VENDOR INFORMATION",
  "contact-information": "Contact Information",
  "create-vendor": "Create Vendor",
  "edit-vendor": "Edit Vendor",
  "add-more-contact": "Add more contact",

  // customer
  "customer-information": "CUSTOMER INFORMATION",
  "select-customer": "SELECT CUSTOMER",
  "create-customer": "Create Customer",
  "edit-customer": "Edit Customer",
  nearby: "Nearby",
  "city-province": "City / Province",
  district: "District",
  commune: "Commune",
  "google-map-url": "Google Map URL",

  // purchase order
  "purchase-order-number": "Purchase Order No.",
  "reference-number": "REFERENCE NO.",
  "expected-delivery-date": "EXPECTED DELIVERY DATE",
  "expiry-date": "Expiry Date",
  "create-purchase": "Create Purchase",
  "edit-purchase": "Edit Purchase",

  // sale-order
  "order-not-found": "Order not found",
  "order-number": "Order No.",
  "customer-name": "Customer Name",
  "create-order": "Create Order",
  "edit-order": "Edit Order",
  "select-payment-methods": "SELECT PAYMENT METHODS",
  "payment-methods": "Payment Methods",
  "cash-on-delivery": "Cash on delivery",
  "online-payment": "Online Payment",
  "cash-transfer": "Cash Transfer",
  "bank-transfer": "Bank Transfer",

  // location
  "create-location": "Create Location",
  "location-form-title": "LOCATION INFORMATION",

  //login-page
  "email-or-phone": "Email / Phone Number",
  password: "Password",
  login: "Login",
  "forgot-password": "Forgot Password?",

  // Sidebar
  "app-sidebar-dashboard": "Dashboard",

  "app-sidebar-company": "Company",
  "app-sidebar-company-list": "Companies",
  "app-sidebar-company-new": "Create",

  "app-sidebar-candidate": "Candidate",
  "app-sidebar-candidate-list": "Candidates",
  "app-sidebar-candidate-new": "Create",

  "app-sidebar-user": "User",
  "app-sidebar-user-list": "Users",
  "app-sidebar-user-new": "Create",

  "app-sidebar-job": "Jobs",
  "app-sidebar-job-list": "Jobs",
  "app-sidebar-job-new": "Create",

  "app-sidebar-setting": "Settings",
  "app-sidebar-setting-profile": "Update Profile",
  // dialog
  "app-dialog-cancelButton": "Cancel",
  "app-dialog-addButton": "Add",
  "app-dialog-updateButton": "Update",
};

export default APP_EN;
