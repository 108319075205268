import { createSelector } from "reselect";

const dashboardReducerSelector = state => state.dashboardReducer;

export const dashboardSelector = createSelector(
  dashboardReducerSelector,
  ({
    dashboardData,
  }) => ({
    dashboardData
  })
);