import { Typography, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import MessageApi from "src/api/message";
import MessageCard from "src/components/message-card";

const MessagePage = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const getMessages = async () => {
      const res = await MessageApi.getAll();
      console.log(res.data.messages);
      setMessages(res.data.messages);
    };
    getMessages();
  }, []);
  return (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h1">Message</Typography>
        </Grid>
        <Grid item>
          {/** message card */}
          {messages.map(
            ({
              _id,
              recipient,
              subject,
              content,
              updatedAt,
              isAccepted,
              isResponded,
            }) => (
              <MessageCard
                key={_id}
                _id={recipient._id}
                username={recipient.firstname + " " + recipient.lastname}
                profileUrl={recipient.profileUrl}
                organization={recipient.currentLocation}
                subject={subject}
                content={content}
                createdAt={updatedAt}
                isAccepted={isAccepted}
                isResponded={isResponded}
              />
            )
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default MessagePage;
