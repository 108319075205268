import { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Chip,
  Button,
  Slider,
  makeStyles,
  Avatar,
} from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AvatarEditor from "react-avatar-editor";
import { Controller, useFormContext } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import InputMask from "react-input-mask";
import { top100Jobs } from "./top-100-films";

const useStyles = makeStyles((themes) => ({
  uploadArea: {
    display: "flex",
    border: "1px solid #ddd",
    color: "#aaa",
    padding: "0.25em 1em",
    justifyContent: "center",
    textAlign: "center",
  },
  avatar: {
    width: 175,
    height: 175,
    border: `4px solid ${themes.palette.primary.main}`,
  },
}));

const CandidateForm = ({ setDeletedRelatedDocuments }) => {
  const classes = useStyles();
  const hiddenFileInput = useRef();
  const avatarRef = useRef();
  const [imageScale, setImageScale] = useState(1.3);
  const { register, control, setValue, watch } = useFormContext();
  const [profileImage, setProfileImage] = useState("/profile-placeholder.png");
  const selectedFiles = watch("candidate.relatedDocuments") || [];
  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop: (files) => {
      setValue("candidate.relatedDocuments", [...selectedFiles, ...files]);
    },
  });

  const handleDeleteUpload = (chipToDelete) => {
    // set state from the key to delete
    if (chipToDelete.Key) {
      setDeletedRelatedDocuments((prev) => [...prev, chipToDelete]);
    }

    setValue(
      "candidate.relatedDocuments",
      selectedFiles.filter((item) => item !== chipToDelete)
    );
  };

  /// handle when user select a new upload image
  const handleProfileChange = (e) => {
    // set image key to delete here
    console.log(typeof e.target.files[0]);
    setProfileImage(e.target.files[0]);
  };

  /// handle when the user make changes to avatar component
  const updateProfileImage = () =>
    avatarRef.current.getImage().toBlob((blob) => {
      setValue("candidate.profileImage", blob);
    });

  useEffect(() => {
    register("candidate.jobs");
    register("candidate.relatedDocuments");
  }, [register]);

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={1}
        style={{ marginBottom: "2em" }}
      >
        <Grid item>
          {typeof profileImage === "object" ? (
            <>
              <AvatarEditor
                ref={avatarRef}
                className={classes.avatar}
                image={profileImage}
                width={200}
                height={200}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={imageScale}
                rotate={0}
                borderRadius={250}
                onImageReady={updateProfileImage}
                onImageChange={updateProfileImage}
              />
              <Slider
                orientation="vertical"
                step={0.01}
                defaultValue={1.2}
                aria-labelledby="vertical-slider"
                style={{ height: "258px" }}
                min={1}
                max={3}
                onChange={(e, val) => setImageScale(val)}
              />
            </>
          ) : (
            <Avatar
              src={watch("candidate.profileUrl")}
              className={classes.avatar}
            />
          )}
        </Grid>
        <Grid item>
          <Button
            startIcon={<CloudUploadOutlinedIcon />}
            onClick={(e) => hiddenFileInput.current.click()}
          >
            Upload Picture
          </Button>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleProfileChange}
            style={{ display: "none" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                // error={false}
                // helperText="Incorrect entry."
                value={value}
                onChange={onChange}
                label="First Name"
                inputRef={ref}
                fullWidth
              />
            )}
            name="candidate.firstname"
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                // error={false}
                // helperText="Incorrect entry."
                onChange={onChange}
                value={value}
                label="Last Name"
                inputRef={ref}
                fullWidth
              />
            )}
            name="candidate.lastname"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                // error={false}
                // helperText="Incorrect entry."
                onChange={onChange}
                value={value}
                label="Address (Optional)"
                inputRef={ref}
                fullWidth
              />
            )}
            name="candidate.address"
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                // error={false}
                // helperText="Incorrect entry."
                onChange={onChange}
                value={value}
                label="Email Address"
                inputRef={ref}
                fullWidth
              />
            )}
            name="candidate.email"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel htmlFor="candidate-phone">Phone Number</InputLabel>
            <Controller
              render={({ field: { onChange, value } }) => (
                <InputMask
                  mask="(999)-99-999-9999"
                  maskChar={null}
                  onChange={onChange}
                  value={value}
                >
                  {() => <Input />}
                </InputMask>
              )}
              name="candidate.phone"
              defaultValue=""
              control={control}
            />
            <FormHelperText></FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="workPreference">Work Preference</InputLabel>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="workPreference"
                  value={value}
                  fullWidth
                  onChange={onChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {[
                    "Fully Remote",
                    "Fully Office Based",
                    "Hybrid 1 day remote",
                    "Hybrid 2 days remote",
                    "Hybrid 2 days remote",
                    "Hybrid 3 days remote",
                    "Hybrid 4 days remote",
                    "Totally Flexible",
                  ].map((item, key) => (
                    <MenuItem value={item} key={key}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="candidate.workPreference"
              control={control}
            />
            <FormHelperText>Preferred Logistic</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="employmentType">Employment Category</InputLabel>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="employmentType"
                  fullWidth
                  onChange={onChange}
                  value={value}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {[
                    "HEOR",
                    "Market Access",
                    "Real World Evidence",
                    "Epidemiology",
                    "Pricing and Reimbursement",
                  ].map((item, key) => (
                    <MenuItem value={item} key={key}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="candidate.employmentType"
              control={control}
            />
            <FormHelperText>Job Type</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item container md={6} spacing={2} justifyContent="space-around">
          <Grid item xs={6}>
            <Controller
              render={({ field: { onChange, value, ref } }) => (
                <TextField
                  type="number"
                  // error={false}
                  // helperText="Incorrect entry."
                  onChange={onChange}
                  value={value}
                  label="Salary From"
                  inputRef={ref}
                  fullWidth
                />
              )}
              name="candidate.salary.from"
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              render={({ field: { onChange, value, ref } }) => (
                <TextField
                  // error={false}
                  // helperText="Incorrect entry."
                  type="number"
                  onChange={onChange}
                  value={value}
                  label="Salary To"
                  inputRef={ref}
                  fullWidth
                />
              )}
              name="candidate.salary.to"
              control={control}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="availability">Availability</InputLabel>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="availability"
                  fullWidth
                  onChange={onChange}
                  value={value}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {[
                    "Immediate",
                    "1 Day Notice",
                    "2 Days Notice",
                    "3 Days Notice",
                    "4 Days Notice",
                    "5 Days Notice",
                    "6 Days Notice",
                    "1 Week Notice",
                    "2 Weeks Notice",
                    "3 Weeks Notice",
                    "4 Weeks Notice",
                    "1 Month Notice",
                    "2 Months Notice",
                    "3 Months Notice",
                    "4 Months Notice",
                    "5 Months Notice",
                    "6 Months Notice",
                  ].map((item, key) => (
                    <MenuItem value={item} key={key}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="candidate.availability"
              control={control}
            />
            <FormHelperText></FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  freeSolo
                  multiple
                  value={value}
                  options={[{ title: "Full-Time" }, { title: "Part-Time" }]}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, items) => onChange(items)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Hours Availability"
                      placeholder="Full-Time or Part-Time"
                      onChange={onChange}
                    />
                  )}
                />
              )}
              name="candidate.hourAvailability"
              control={control}
            />
            <FormHelperText></FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  freeSolo
                  multiple
                  value={value}
                  options={[{ title: "Permanent" }, { title: "Contract" }]}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, items) => onChange(items)}
                  // defaultValue={}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Contract Type"
                      placeholder="Contract Type"
                    />
                  )}
                />
              )}
              name="candidate.contractType"
              control={control}
            />
            <FormHelperText>Type of Contract</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="relocation">Relocation</InputLabel>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="relocation"
                  fullWidth
                  onChange={onChange}
                  value={value}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={true} selected>
                    Yes
                  </MenuItem>
                  <MenuItem value={false} selected={true}>
                    No
                  </MenuItem>
                </Select>
              )}
              name="candidate.relocation"
              control={control}
            />
            <FormHelperText>Open to Relocation</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="currentLocation">Current Location</InputLabel>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="currentLocation"
                  fullWidth
                  onChange={onChange}
                  value={value}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {[
                    "Southeast",
                    "Southwest",
                    "London",
                    "West Midlands",
                    "East Midlands",
                    "East Anglia",
                    "Northeast",
                    "Northwest",
                    "Scotland",
                    "Wales",
                    "Ireland",
                    "Northern Ireland",
                  ].map((item, key) => (
                    <MenuItem value={item} key={key}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="candidate.currentLocation"
              control={control}
            />
            <FormHelperText></FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  freeSolo
                  multiple
                  options={[
                    { title: "Pharmaceutical" },
                    { title: "Biopharmaceutical" },
                    { title: "Cell and Gene Therapy" },
                    { title: "Orphan Drug / Rare Disease" },
                    { title: "MedTech" },
                    { title: "Medical Device" },
                    { title: "Diagnostics" },
                    { title: "Specialist Consultancy" },
                    { title: "CRO" },
                    { title: "Government Organisation" },
                    { title: "Management Consultancy" },
                    { title: "Public Health" },
                    { title: "Academia" },
                    { title: "Research" },
                    { title: "Generic" },
                  ]}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, items) => onChange(items)}
                  value={value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company Type"
                      placeholder="Company Type"
                    />
                  )}
                />
              )}
              name="candidate.companyType"
              control={control}
            />
            <FormHelperText></FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="sponsorship">Requires Sponsorship</InputLabel>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="sponsorship"
                  fullWidth
                  value={value}
                  onChange={onChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={true} selected>
                    Yes
                  </MenuItem>
                  <MenuItem value={false} selected={true}>
                    No
                  </MenuItem>
                </Select>
              )}
              name="candidate.sponsorship"
              control={control}
            />
            <FormHelperText>Is the sponsorship required?</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="previousLineManagement">
              Previous Line Management
            </InputLabel>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="previousLineManagement"
                  fullWidth
                  onChange={onChange}
                  value={value}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={true} selected>
                    Yes
                  </MenuItem>
                  <MenuItem value={false} selected={true}>
                    No
                  </MenuItem>
                </Select>
              )}
              name="candidate.previousLineManagement"
              control={control}
            />
            <FormHelperText></FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Controller
            render={({ field: { onChange, value, ref } }) => {
              return (
                <Autocomplete
                  freeSolo
                  multiple
                  ref={ref}
                  value={value}
                  options={[
                    { title: "Bachelor's Degree" },
                    { title: "Master's Degree" },
                    { title: "Ph.D" },
                    { title: "DPhil" },
                    { title: "MPharm" },
                    { title: "MBA" },
                  ]}
                  onChange={(e, items) => onChange(items)}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Qualifications"
                      placeholder="Qualifications"
                    />
                  )}
                />
              );
            }}
            name="candidate.qualifications"
            control={control}
          />
          {/* {console.log("qualification", watch("candidate.qualifications"))} */}
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                freeSolo
                multiple
                value={value}
                options={top100Jobs}
                getOptionLabel={(option) => option.title}
                onChange={(e, items) => onChange(items)}
                // defaultValue={}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Recommended Jobs"
                    placeholder="Recommend a Job"
                  />
                )}
              />
            )}
            name="candidate.jobs"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Controller
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                // error={false}
                // helperText="Incorrect entry."
                onChange={onChange}
                value={value}
                label="Executive Summary"
                inputRef={ref}
                fullWidth
                multiline
                // helperText="Leave the summary for your findings on the candidate"
              />
            )}
            name="candidate.executiveSummary"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Controller
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                // error={false}
                // helperText="Incorrect entry."
                onChange={onChange}
                multiline
                value={value}
                label="Motivations, Drivers and Ambitions "
                inputRef={ref}
                fullWidth
                // helperText="Leave your motivations on the candidate"
              />
            )}
            name="candidate.motivations"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <label
            isdragaccept={isDragAccept.toString()}
            {...getRootProps({ onClick: (e) => e.preventDefault() })}
            className={classes.uploadArea}
          >
            <p>
              <CloudUploadOutlinedIcon style={{ fontSize: 75 }} />
              <br />
              Drag and drop file here, or click to select file
            </p>
            <input type="file" {...getInputProps()} />
          </label>
          {selectedFiles?.map((item) => (
            <Chip
              label={item.name || item.path}
              onDelete={() => handleDeleteUpload(item)}
              color="primary"
              style={{ marginTop: 5, marginRight: 3 }}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default CandidateForm;
