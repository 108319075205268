import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    // color: theme.palette.primary.main,
    fontSize: "1em",
    fontWeight: "bold",
    borderBottom: "1px solid grey",
    padding: "0.5em 0.5em",
  },
}));

const ContentSection = ({ title, children }) => {
  const classes = useStyles();
  // const history = useHistory();

  return (
    <Grid container direction="column" spacing={2} style={{ marginBottom: 25 }}>
      <Grid item className={classes.title} xs={12}>
        {title}
      </Grid>
      <Grid item style={{ width: "100%" }}>
        {children}
      </Grid>
    </Grid>
  );
};

export default ContentSection;
