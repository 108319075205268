import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { useController } from "react-hook-form";

const ControlledTextField = ({
  control,
  name,
  rules,
  defaultValue = "",
  placeholder,
  xs,
  md,
  lg,
  ...props
}) => {
  const {
    field: { onChange, onBlur, name: componentName, value, ref },
    // fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <Grid item xs={xs} md={md} lg={lg}>
      <TextField
        {...props}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        name={componentName}
        control={control}
        error={errors[componentName] ? true : false}
        inputRef={ref}
      />
    </Grid>
  );
};

export default ControlledTextField;
