import {
  Grid,
  Avatar,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import { CancelRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  avatar: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
}));

const ProfilePicture = ({
  src,
  name,
  position,
  handleRemove,
  avatarSize = 100,
}) => {
  const classes = useStyles();

  return (
    <Grid container item spacing={1}>
      <Grid
        container
        item
        justifyContent="center"
        xs={12}
        style={{ position: "relative" }}
      >
        {handleRemove && (
          <IconButton
            aria-lable="delete"
            style={{
              position: "absolute",
              zIndex: 10,
              left: -7,
              top: -7,
            }}
            onClick={handleRemove}
          >
            <CancelRounded
              style={{
                color: "red",
                backgroundColor: "white",
                borderRadius: 10,
              }}
            />
          </IconButton>
        )}
        <Avatar
          src={src}
          className={classes.avatar}
          style={{ width: avatarSize, height: avatarSize }}
        />
      </Grid>
      {name && (
        <Grid container item>
          {name && (
            <Grid container item justifyContent="center" xs={12}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                {name}
              </Typography>
            </Grid>
          )}
          {position && (
            <Grid container item justifyContent="center" xs={12}>
              <Typography>{position}</Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ProfilePicture;
