import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  makeStyles,
  Button,
  TextField,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  FormControl,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Rating from "@material-ui/lab/Rating";
import { Delete, AddCircle, Watch } from "@material-ui/icons";
import {
  useForm,
  useFieldArray,
  FormProvider,
  Controller,
} from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import CandidateForm from "./candidate-form";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import PaperContainerWithTitle from "src/components/paper-container-with-title";
import s3FileUploading from "src/utils/s3-file-uploading";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    marginBottom: 60,
    display: "flex",
  },
  submit: {
    backgroundColor: "rgba(111, 111, 111, 0.3)",
    position: "fixed",
    width: "100vw",
    bottom: 0,
    left: 0,
    textAlign: "right",
  },
  buttonPrimary: {
    backgroundColor: "#484848",
    color: "white",
    borderRadius: 5,
    margin: "0.25em 0.4em",
    padding: "0.75em 2em",
    "&:hover": {
      backgroundColor: "#262626",
    },
  },
  buttonDanger: {
    backgroundColor: "#D32121",
    color: "white",
    borderRadius: 5,
    margin: "0.25em 0.4em",
    padding: "0.75em 2em",
    "&:hover": {
      backgroundColor: "#C21010",
    },
  },
}));

const CreateCandidate = () => {
  const classes = useStyles();
  const history = useHistory();
  const { candidateId } = useParams();
  const [deletedRelatedDocuments, setDeletedRelatedDocuments] = useState([]);

  const methods = useForm({
    defaultValues: {
      candidate: {
        firstname: "",
        lastname: "",
        address: "",
        phone: "",
        email: "",
        workPreference: "",
        employmentType: "",
        availability: "",
        relocation: false,
        hourAvailability: [],
        contractType: [],
        currentLocation: "",
        sponsorship: false,
        previousLineManagement: false,
        jobs: [],
        executiveSummary: "",
        motivations: "",
        salary: {},
        companyType: [],
        qualifications: [],
        skills: [{ value: "", startDate: new Date(), endDate: new Date() }],
        therapeutics: [
          { value: "", startDate: new Date(), endDate: new Date() },
        ],
        languages: [{}],
      },
    },
  });
  const {
    fields: skillFields,
    append: appendSkill,
    remove: removeSkill,
  } = useFieldArray({
    control: methods.control,
    name: "candidate.skills",
  });

  const {
    fields: therapeuticFields,
    append: appendTherapeutic,
    remove: removeTherapeutic,
  } = useFieldArray({
    control: methods.control,
    name: "candidate.therapeutics",
  });

  const {
    fields: languageFields,
    append: appendLanguage,
    remove: removeLanguage,
  } = useFieldArray({
    control: methods.control,
    name: "candidate.languages",
  });

  const onSubmit = async (data, e) => {
    const candidate = data.candidate;
    let profileImage, relatedDocuments;

    try {
      [profileImage, relatedDocuments] = await Promise.all([
        await s3FileUploading(`profile-pictures`, candidate.profileImage, {
          thumbnail: true,
        }),
        await s3FileUploading(
          "candidate-documents",
          candidate.relatedDocuments
        ),
      ]);

      await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/candidate`, {
        ...candidate,
        profileImage: profileImage ? profileImage[0] : null,
        relatedDocuments,
      });

      if (deletedRelatedDocuments.length > 0) {
        axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/aws-s3/remove-files`,
          {
            Keys: deletedRelatedDocuments,
          }
        );
      }

      history.push("/dashboard/candidate");
    } catch (err) {
      console.log("err uploading file", err);
      const filesToRemove = [];
      profileImage && filesToRemove.push(profileImage[0]);
      relatedDocuments?.length > 0 && filesToRemove.push(...relatedDocuments);

      console.log("filesToRemove", filesToRemove);
      if (filesToRemove.length > 0)
        axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/aws-s3/remove-files`,
          {
            Keys: filesToRemove,
          }
        );
    }
  };

  useEffect(() => {
    const fetchCandidateInfo = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/candidate/${candidateId}`
      );
      methods.reset({
        candidate: {
          ...res.data.candidate,
        },
      });
    };

    candidateId && fetchCandidateInfo();
  }, [candidateId, methods]);

  return (
    <FormProvider {...methods}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container className={classes.root}>
            <PaperContainerWithTitle title="Personal Information">
              <CandidateForm
                setDeletedRelatedDocuments={setDeletedRelatedDocuments}
                defaultProfileImage={methods.watch("candidate.profileUrl")}
              />
            </PaperContainerWithTitle>
            <PaperContainerWithTitle title="Technical Skills">
              {skillFields.map((field, index) => (
                <Grid container key={field.id} spacing={1}>
                  <Grid xs={5} item>
                    <Controller
                      render={({ field: { onChange, value, ref } }) => (
                        <Autocomplete
                          freeSolo
                          // autoCapitalize
                          autoHighlight
                          ref={ref}
                          value={value}
                          options={[
                            { title: "Health Economics" },
                            { title: "Health Economist" },
                            { title: "HEOR" },
                            { title: "Health Economic Modelling" },
                            { title: "Markov Models" },
                            { title: "Bayesian Models" },
                            { title: "Statistics" },
                            { title: "Statistical Models" },
                            { title: "Outcomes Research" },
                            { title: "Patient Reported Outcomes" },
                            { title: "Patient Centred Outcomes" },
                            { title: "Market Access" },
                            { title: "HTA" },
                            { title: "HTA Submission Writing (Individual)" },
                            { title: "HTA Submission Writing (Team)" },
                            { title: "Real World Data" },
                            { title: "Real World Evidence" },
                            { title: "Epidemiology" },
                            { title: "Python" },
                            { title: "R" },
                            { title: "SQL" },
                            { title: "Excel" },
                            { title: "SAS" },
                            { title: "Systematic Reviews" },
                            { title: "Pricing" },
                            { title: "Pricing and Reimbursement" },
                            { title: "Reimbursement" },
                            { title: "Reimbursement Submission Writing" },
                          ]}
                          getOptionLabel={(option) => option.title}
                          onChange={(event, item) => onChange(item)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Skill"
                              placeholder="Skill"
                            />
                          )}
                        />
                      )}
                      name={`candidate.skills.${index}.value`}
                      control={methods.control}
                    />
                  </Grid>
                  <Grid xs={2} item>
                    <Controller
                      render={({ field: { value, onChange, ref } }) => (
                        <KeyboardDatePicker
                          fullWidth
                          // disableToolbar
                          views={["month", "year"]}
                          variant="inline"
                          format="MM/yyyy"
                          id={`skill-start-date-inline-${index}`}
                          label="Start Date"
                          value={value}
                          autoOk={true}
                          onChange={onChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      )}
                      name={`candidate.skills.${index}.startDate`}
                      control={methods.control}
                    />
                  </Grid>
                  <Grid xs={2} item>
                    <Controller
                      render={({ field: { value, onChange, ref } }) => (
                        <KeyboardDatePicker
                          fullWidth
                          // disableToolbar
                          views={["month", "year"]}
                          variant="inline"
                          format="MM/yyyy"
                          id={`skill-end-date-inline-${index}`}
                          label="End Date"
                          autoOk={true}
                          value={value}
                          onChange={onChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      )}
                      name={`candidate.skills.${index}.endDate`}
                      control={methods.control}
                    />
                  </Grid>
                  <Grid xs={2} item>
                    <FormControl fullWidth>
                      Proficiency
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <Grid container spacing={2}>
                            <Grid item>
                              <Rating
                                name={`candidate.skills.${index}.proficiency`}
                                value={Number(value)}
                                precision={0.5}
                                onChange={onChange}
                              />
                            </Grid>
                            <Grid item>
                              {value !== null && languageRatingLabels[value]}
                            </Grid>
                          </Grid>
                        )}
                        name={`candidate.skills.${index}.proficiency`}
                        control={methods.control}
                      />
                    </FormControl>
                  </Grid>
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeSkill(index)}
                    color="primary"
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              ))}
              <br />
              <Button
                onClick={() =>
                  appendSkill({
                    startDate: new Date(),
                    endDate: new Date(),
                  })
                }
                startIcon={<AddCircle />}
              >
                Add Skill
              </Button>
            </PaperContainerWithTitle>
            <PaperContainerWithTitle title="Therapeutic Experience">
              {therapeuticFields.map((field, index) => (
                <Grid container key={field.id} spacing={1}>
                  <Grid xs={5} item>
                    <Controller
                      render={({ field: { onChange, value, ref } }) => (
                        <Autocomplete
                          freeSolo
                          autoHighlight
                          value={value}
                          ref={ref}
                          options={[
                            { title: "Oncology" },
                            { title: "Cardiovascular" },
                            { title: "Dermatology" },
                            { title: "Gastroenterology" },
                            { title: "Hematology" },
                            { title: "Immunology" },
                            { title: "Infectious Diseases" },
                            { title: "Metabolism and Endocrinology" },
                            { title: "Neurology" },
                            { title: "Ophthalmology" },
                            { title: "Orthopaedics" },
                            { title: "Psychiatry" },
                            { title: "Rheumatology" },
                            { title: "Substance Abuse" },
                            { title: "Urology" },
                            { title: "Women’s Heath" },
                          ]}
                          getOptionLabel={(option) => option.title}
                          onChange={(event, item) => onChange(item)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Therapeutic"
                              placeholder="Therapeutic"
                            />
                          )}
                        />
                      )}
                      name={`candidate.therapeutics.${index}.value`}
                      control={methods.control}
                    />
                  </Grid>
                  <Grid xs={2} item>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <KeyboardDatePicker
                          // disableToolbar
                          views={["month", "year"]}
                          variant="inline"
                          format="MM/yyyy"
                          id={`therapeutic-start-date-inline-${index}`}
                          label="Start Date"
                          autoOk={true}
                          value={value}
                          onChange={onChange}
                          fullWidth
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      )}
                      name={`candidate.therapeutics.${index}.startDate`}
                      control={methods.control}
                    />
                  </Grid>
                  <Grid xs={2} item>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <KeyboardDatePicker
                          // disableToolbar
                          fullWidth
                          views={["month", "year"]}
                          variant="inline"
                          format="MM/yyyy"
                          id={`therapeutic-end-date-inline-${index}`}
                          label="End Date"
                          autoOk={true}
                          value={value}
                          onChange={onChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      )}
                      name={`candidate.therapeutics.${index}.endDate`}
                      control={methods.control}
                    />
                  </Grid>
                  <Grid xs={2} item>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          fullWidth
                          id={`therapeutic-percentage-inline-${index}`}
                          label="Working Percentage"
                          value={value}
                          onChange={onChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      name={`candidate.therapeutics.${index}.percentage`}
                      control={methods.control}
                    />
                  </Grid>
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeTherapeutic(index)}
                    color="primary"
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              ))}
              <br />
              <Button
                onClick={() =>
                  appendTherapeutic({
                    startDate: new Date(),
                    endDate: new Date(),
                  })
                }
                startIcon={<AddCircle />}
              >
                Add Therapeutic
              </Button>
            </PaperContainerWithTitle>
            <PaperContainerWithTitle title="Language and Proficiency">
              {languageFields.map((field, index) => (
                <Grid container key={field.id} spacing={3}>
                  <Grid xs={6} item>
                    <Controller
                      render={({ field: { onChange, value, ref } }) => (
                        <TextField
                          // error={false}
                          // helperText="Incorrect entry."
                          onChange={onChange}
                          value={value}
                          label="Language"
                          inputRef={ref}
                          fullWidth
                        />
                      )}
                      name={`candidate.languages.${index}.name`}
                      control={methods.control}
                    />
                  </Grid>
                  <Grid xs={5} item>
                    <FormControl fullWidth>
                      Proficiency
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <Grid container spacing={2}>
                            <Grid item>
                              <Rating
                                name={`candidate.languages.${index}.proficiency`}
                                value={Number(value)}
                                precision={0.5}
                                onChange={onChange}
                              />
                            </Grid>
                            <Grid item>
                              {value !== null && languageRatingLabels[value]}
                            </Grid>
                          </Grid>
                        )}
                        name={`candidate.languages.${index}.proficiency`}
                        control={methods.control}
                      />
                    </FormControl>
                  </Grid>
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeLanguage(index)}
                    color="primary"
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              ))}
              <br />
              <Button
                onClick={() => appendLanguage({})}
                startIcon={<AddCircle />}
              >
                Add Language
              </Button>
            </PaperContainerWithTitle>
            <Box className={classes.submit}>
              <Box margin={2} marginRight={8}>
                <Button
                  className={classes.buttonDanger}
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
                <Button type="submit" className={classes.buttonPrimary}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>
        </form>
      </MuiPickersUtilsProvider>
    </FormProvider>
  );
};

export const languageRatingLabels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

export default CreateCandidate;
