import { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import { useForm } from "react-hook-form";

import PaperContainer from "src/components/paper-container-with-title";
import CandidatePaper from "src/components/candidate-paper";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    marginBottom: 60,
    display: "flex",
  },
  searchBox: {
    width: "35%",
  },
}));

const Candidate = () => {
  const classes = useStyles();
  const { register, setValue, handleSubmit } = useForm({});
  const [candidates, setCandidates] = useState([]);

  useEffect(() => {
    const fetchCandidate = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/candidate`
        );
        setCandidates(data.candidates);
      } catch (err) {
        console.log("failed-to-get-candidate", err);
      }
    };

    register("search");
    fetchCandidate();
  }, [register]);

  const onSubmit = async (data) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/candidate?search=${data.search}`
      );
      setCandidates(res.data.candidates);
    } catch (err) {
      console.log("failed-to-search-candidate", err);
    }
  };

  const handleDelete = async (candidateId, index) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/candidate`, {
        data: { candidateId },
      });
      setCandidates((candidates) => {
        const afterDeleted = [...candidates];
        afterDeleted.splice(index, 1);
        return afterDeleted;
      });
    } catch (err) {
      console.log("failed-to-delete-candidate", err);
    }
  };

  return (
    <Grid container className={classes.root}>
      <PaperContainer title="List of Candidates">
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="flex-end">
            <Autocomplete
              freeSolo
              className={classes.searchBox}
              disableClearable
              options={["Candidate Name", "Chea Sowattana", "Michael Devel"]}
              onChange={(e, options) => setValue("search", options)}
              onInputChange={(e, options) => setValue("search", options)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search for Candidate"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    endAdornment: (
                      <InputAdornment>
                        <IconButton type="submit">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </form>
      </PaperContainer>
      <Grid item xs={12}>
        {candidates.map((candidate, key) => (
          <CandidatePaper
            {...candidate}
            handleDelete={handleDelete}
            inputKey={key}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default Candidate;
