import { Grid, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    width: "100%",
    padding: "0.5em",
    borderBottom: "1px solid #cccccc",
    fontSize: "1.1em",
    fontWeight: "bold",
  },
  content: {
    marginTop: "3em",
  },
  paperContainer: {
    boxShadow: "0 0 4px #bbbbbb !important",
    padding: "2.5em 3em",
    marginBottom: "1em",
    width: "100%",
  },
}));

const PaperContainerWithTitle = ({ title, children, titleButton }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paperContainer}>
      <Grid item className={classes.title}>
        {title} {titleButton}
      </Grid>
      <Grid className={classes.content}>{children}</Grid>
    </Paper>
  );
};

export default PaperContainerWithTitle;
